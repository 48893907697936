import { useSession } from '@invisible/authentication/client'
import { OnlineStatusProvider } from '@invisible/ui/hooks/use-online-status'
import { InvisibleLogoWithTextIcon } from '@invisible/ui/icons'
import { ProfileMenu } from '@invisible/ui/profile-menu'

export const Header = () => {
  const { data: session } = useSession()
  const user = session?.user
  return (
    <div className='bg-theme-main fixed top-0 z-20 flex h-[70px] w-full items-center justify-between'>
      <div className='flex gap-6 pl-12'>
        <InvisibleLogoWithTextIcon height='32px' width='150px' color='white' />
      </div>
      <div className='pr-12'>
        {user && (
          <OnlineStatusProvider>
            <ProfileMenu
              profilePicture={user?.image || ''}
              profileText={user?.name || ''}
              profileSubText={user?.email || ''}
              profileOptions={[]}
              textColor='white'
            />
          </OnlineStatusProvider>
        )}
      </div>
    </div>
  )
}
