import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CheckIcon, DogIcon as _DogIcon, SavingIcon } from '@invisible/ui/icons'
import { Text } from '@invisible/ui/text'
import { keyframes, styled } from '@invisible/ui/themes'
import { useToasts } from '@invisible/ui/toasts'
import axios from 'axios'
import pMap from 'p-map'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import type { ISelectedBillRow } from './table-components/CustomToolbar'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
const SpinnerIcon = styled(SavingIcon)`
  animation: ${spin} 1s linear infinite;
  color: #2ce28b;
  margin-right: 5px;
`

const _AgentName = styled(Text)<{
  status: 'pending' | 'drafting' | 'done' | 'failed'
}>`
  color: ${({ status }) => (status === 'pending' ? '#716F79' : 'black')};
  margin-left: 20px;
`

const _FontAwesomeIcon = styled(FontAwesomeIcon)`
  color: red;
  font-size: 15px;
`

const Agent = ({ children, status }: {
  children: React.ReactNode
  status: 'pending' | 'drafting' | 'done' | 'failed'
}) => (
  <div className='flex w-full items-center gap-2'>
    <div className='flex justify-end'>
      {status === 'drafting' ? (
        <div className='flex items-center gap-1'>
          <SpinnerIcon />
          <div>Drafting</div>
        </div>
      ) : status === 'done' ? (
        <CheckIcon />
      ) : status === 'failed' ? (
        <_FontAwesomeIcon icon={faTimes} />
      ) : null}
    </div>
    <_AgentName status={status}>{children}</_AgentName>
  </div>
)

interface IProps {
  agents: ISelectedBillRow[]
  setIsDrafting: Dispatch<SetStateAction<boolean>>
}

export const DraftingPage = ({ agents, setIsDrafting }: IProps) => {
  const [processedAgents, setProcessedAgents] = useState<{
    [id: string | number]: 'pending' | 'drafting' | 'done' | 'failed'
  }>(() =>
    agents.reduce(
      (acc: { [id: string | number]: 'pending' | 'drafting' | 'done' | 'failed' }, curr) => {
        acc[curr.id as string] = 'pending'
        return acc
      },
      {}
    )
  )

  const [isInProgress, setIsInProgress] = useState(false)

  const { addToast } = useToasts()

  useEffect(() => {
    const main = async () => {
      setIsInProgress(true)
      await pMap(
        agents,
        async (agent) => {
          try {
            setProcessedAgents((prev) => ({ ...prev, [agent.id]: 'drafting' }))
            await axios.post('/api/netsuite/draftBill', {
              billId: agent.manticoreBillId,
              drafterEmail: agent.drafterEmail,
              userEmail: agent.userEmail,
            })
            setProcessedAgents((prev) => ({ ...prev, [agent.id]: 'done' }))
          } catch (err) {
            setProcessedAgents((prev) => ({ ...prev, [agent.id]: 'failed' }))
            addToast(`Failed to draft bill for ${agent.name}`, {
              appearance: 'error',
            })
          }
        },
        { concurrency: 5 }
      )
      setIsInProgress(false)
      // Timeout so user has time to view drafting logs
      setTimeout(() => setIsDrafting(false), 5000)
    }
    if (!isInProgress) main()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='mt-8 w-full overflow-y-auto p-8'>
      <div className='flex w-full justify-center text-xl'>Sending bills to Finance!</div>
      <div className='mt-10 flex w-full flex-col items-center space-y-2'>
        {agents.map((agent) => (
          <Agent key={agent.id} status={processedAgents[agent.id as string | number]}>
            {agent.name}
          </Agent>
        ))}
      </div>
    </div>
  )
}
