import { useSession } from '@invisible/authentication/client'
import { adminTheme, MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import Stack from '@mui/material/Stack'
import { isEmpty } from 'lodash/fp'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { DraftingPage } from '../../components/DraftingPage'
import ManticoreBillsTable from '../../components/ManticoreBillsTable'
import type { ISelectedBillRow } from '../../components/table-components/CustomToolbar'

export const ManticoreAgentDashboard = () => {
  const { data: session } = useSession()
  const user = session?.user
  const router = useRouter()
  const { cycleIndex } = router.query

  const [selectedBillRows, setSelectedBillRows] = useState<ISelectedBillRow[]>([])

  const [selectedCycleIndex, setSelectedCycleIndex] = useState(1)
  const [isDrafting, setIsDrafting] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [clearRowSelection, setClearRowSelection] = useState(false)

  useEffect(() => {
    if (!isDrafting) {
      setSelectedBillRows([])
      setClearRowSelection(true)
      setRefetch(true)
    }
  }, [isDrafting])

  useEffect(() => {
    if (router.isReady) {
      setSelectedCycleIndex(
        !isEmpty(cycleIndex) && !isNaN(Number(cycleIndex)) ? Number(cycleIndex) : 1
      )
    }
  }, [cycleIndex, router.isReady])

  if (isDrafting) return <DraftingPage agents={selectedBillRows} setIsDrafting={setIsDrafting} />

  return (
    <div className='flex min-h-screen'>
      <MUIThemeProvider customTheme={adminTheme}>
        <Stack
          sx={{
            margin: 2,
            boxSizing: 'border-box',
            overflow: 'auto',
            width: '100%',
          }}
          direction='column'>
          <ManticoreBillsTable
            key={selectedCycleIndex}
            cycleIndex={selectedCycleIndex}
            setSelectedBillRows={setSelectedBillRows}
            drafterEmail={user?.email ?? ''}
            setIsDrafting={setIsDrafting}
            refetch={refetch}
            setRefetch={setRefetch}
            clearRowSelection={clearRowSelection}
          />
        </Stack>
      </MUIThemeProvider>
    </div>
  )
}
