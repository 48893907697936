import { useAbilityContext } from '@invisible/authorization/client'
import Head from 'next/head'

import { Header } from './Header'

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const [Can] = useAbilityContext()

  return (
    <>
      <Head>
        <title>Welcome to agent-pay!</title>
      </Head>
      <div className='flex max-h-screen min-h-screen flex-col bg-white'>
        <Header />
        <Can I='access' this={{ App: { name: 'agentPay' } }}>
          <div className='mt-[70px] overflow-y-auto'>{children}</div>
        </Can>
      </div>
    </>
  )
}
